/* Checkbox Hack */

input[type=checkbox] {
   display:none;
}

/* Toggled State */

input[type=checkbox]:checked ~ a:before {
   content: '\2713';
   color:var(--main-color);
   border: 1px solid var(--main-color);
}

input[type=checkbox]:not(:checked):not(:indeterminate) ~ a:before {
   content: '\00A0';
   color:var(--main-color);
   border: 1px solid var(--main-color);
}

input[type=checkbox]:indeterminate ~ a:before {
   content: '\2713';
   color: silver;
   border: 1px solid var(--main-color);
}

input[type=checkbox] ~ a:before {
    left: -5px;
    position: relative;
    display: inline-block;
    height: 14px;
    width: 14px;
    font-size: 27px;
    text-align: center;
    line-height: 5px;
}

.mostrar-capa input[type=checkbox] ~ a:before {
    font-size: 24px;
    height: 16px;
    width:16px;
    line-height: 12pt;
}


span.accordion2{
    padding-left: 4pt;
}

div.selector label {
    padding-left: 8pt;
    white-space: nowrap;
}

