body #content {
  .vis-time-axis .vis-text {
    font-size: 10.5pt;
    color: var(--accent-color);
  }

  div.vis-timeline.vis-bottom {
    border: none;
  }

  div.vis-text.vis-minor {
    font-size: 9pt;
    margin-left: 3px;
  }

  div.vis-text.vis-major div {
    font-size: 9pt;
  }

  .vis-time-axis .vis-grid.vis-major {
    border-color: #888;
    border-width: 0.15rem;
  }

  .vis-time-axis .vis-grid.vis-minor {
    border-color: #888;
  }

  div.vis-custom-time.current div {
    color: var(--main-color);
    font-size: 11pt;
    width: 600% !important;
    left: -300% !important;
    text-align: center;
    line-height: 34pt;
  }

  div.vis-panel.vis-center {
    border: none;
  }

  div.vis-item.vis-range.deslizante.vis-editable {
    height: 7pt;
  }

  div.vis-current-time,
  div.vis-custom-time.current {
    border: none;
    background: #00000000;
    width: 4pt;
    cursor: pointer !important;
    z-index: 1;
  }

  div.vis-custom-time:not(.current) {
    cursor: pointer !important;
    width: 5px !important;
    background-color: var(--accent-color) !important;
    z-index: 0 !important;
  }

  div.vis-current-time {
    background: #00000000;
    color: var(--main-color);
    margin-left: -5pt;
    margin-top: -7pt;
  }

  i.fa-caret-square-left,
  i.fa-caret-square-right {
    background-color: white;
    line-height: 9pt;
  }

  div.vis-custom-time-marker {
    background: none !important;
    color: var(--accent-color) !important;
  }
}