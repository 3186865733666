.dataTables_wrapper .dataTables_filter {
    float: left;
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_paginate,
.dataTables_wrapper .dt-buttons {
    float: right;
    display: block;
}

.dataTables_wrapper .dataTables_length {
    padding-top: 0;
    padding-bottom: 0;
    font-size: smaller;
}

div.dataTables_wrapper div.dataTables_info {
    padding-top: 0;
    font-size: smaller;
}

.dataTables_wrapper .dt-buttons a.btn {
    margin-left: 12pt;
    width: 1.5em;
}

table.dataTable {
    width: auto;
}

table.dataTable thead th,
table.dataTable tfoot th {
    font-weight: bold;
}

table.dataTable thead th select,
table.dataTable tfoot th select {
    font-weight: bold;
}

table.dataTable thead th,
table.dataTable thead td {
    padding: 4pt 12pt;
    border-bottom: 1px solid var(--main-color);
    color: var(--main-color);
}

table.dataTable thead th:active,
table.dataTable thead td:active {
    outline: none;
}

table.dataTable tfoot th,
table.dataTable tfoot td {
    padding: 10px 18px 6px 18px;
    border-top: 1px solid var(--accent-color);
}

table.dataTable.hover tbody tr:hover,
table.dataTable.display tbody tr:hover {
    background-color: var(--light-color);
}

table.dataTable tbody th.dt-body-center,
table.dataTable tbody td.dt-body-center,
table.dataTable td.dataTables_empty {
    text-align: center;
}

table.dataTable tbody th.dt-body-right,
table.dataTable tbody td.dt-body-right {
    text-align: right;
}

table.dataTable th.dt-nowrap,
table.dataTable td.dt-nowrap {
    white-space: nowrap;
}
div.dataTables_wrapper div.dataTables_filter label ,
div.dataTables_wrapper div.dataTables_length label {
    font-weight: normal;
    white-space: nowrap;
    text-align: left;
}
div.dataTables_wrapper div.dataTables_filter input {
    margin-left: 0.5em;
    display: inline-block;
    width: auto;
}
div.dataTables_wrapper div.dataTables_length select {
    width: auto;
    display: inline-block;
    appearance: auto;
    padding-inline: inherit;
}